.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  gap: 16px;
  width: 100%;

  @media (max-width: 960px) {
    padding: 8px 16px;
  }
}

.filterWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

// * FilterButtons * //
.filterButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filterButtonsTitle {
  color: var(--content-tertiary);
  font: var(--important-text-sm);
  text-transform: uppercase;
}

.filterButton {
  font: var(--body-text-md) !important;
  padding: 2px 8px !important;
  gap: 4px !important;

  svg path {
    fill: var(--additional-orange-primary);
  }

  &.active {
    border-color: var(--content-primary);
  }

  &.disabled {
    svg path {
      fill: var(--bg-border-active);
    }
  }
}
// * FilterButtons * //
