::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar {
  height: 0px;
}

::-webkit-scrollbar-track {
  background: var(--pure-black);
}

::-webkit-scrollbar-thumb {
  background: var(--white-color);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--white-color);
}
