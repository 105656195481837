// * EscrowVault * //
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.title {
  color: var(--content-primary);
  font: var(--title-md);
  text-transform: uppercase;

  display: flex;
  align-items: center;
  gap: 4px;
}

.errorMessageContainer {
  height: 14px;

  & > * {
    font: var(--body-text-xs);
  }
}

.actionWrapper {
  margin: 0 -24px;
  border-bottom: 0.5px dashed var(--bg-border);
  padding: 0 24px 12px;

  @media (max-width: 640px) {
    margin: 0 -16px;
    padding: 0 16px 12px;
  }
}

.actionButton {
  width: 100%;
}
// * EscrowVault * //

// * EscrowTabs * //
.tabs {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 48px 1fr;
  padding: 12px 0;
}

.tab {
  border: var(--border-less-primary);
  border-radius: 6px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;

  &.active {
    pointer-events: none;
    background: var(--additional-green-secondary);
    border-color: var(--additional-green-secondary);
  }
}

.tabBalance {
  font: var(--important-text-md);
  margin-bottom: 2px;
}

.tabLabel {
  color: var(--content-tertiary);
  text-transform: uppercase;
  font: var(--important-text-xs);
  text-align: center;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;

  &.rotated {
    transform: rotate(180deg);
  }

  svg {
    fill: var(--accent-primary);
    width: 18px;
    height: 18px;
  }
}
// * EscrowTabs * //

// * ClaimSection * //
.claimSection {
  padding-top: 8px;
}

.lenderValtStatsContainer {
  border-top: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &.epochContent {
    flex-direction: column;

    & > div {
      margin-bottom: 8px;
      gap: 4px;
      align-items: unset;
      justify-content: center;
      flex-direction: column;
      width: 100%;
    }

    button {
      width: 100%;
    }
  }
}

.lenderVaultStats {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lenderVaultStatContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}

.lenderVaultStatLabel {
  font: var(--important-text-xs);
}

.lenderVaultStatValue {
  display: flex;
  align-items: center;

  &,
  span {
    font: var(--body-text-sm);
  }

  svg {
    height: 12px;
    width: 12px;

    path {
      fill: revert-layer;
    }
  }
}
// * ClaimSection * //

// * TooltipContent * //
.tooltipContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.tooltipRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 230px;
}

.tooltipRowLabel {
  color: var(--content-secondary);
  font: var(--important-text-sm);
  text-transform: uppercase;

  @media (max-width: 640px) {
    font: var(--important-text-xs);
  }
}

.tooltipRowValue {
  font: var(--body-text-md);

  @media (max-width: 640px) {
    font: var(--body-text-sm);
  }
}
// * TooltipContent * //
