.container {
  cursor: default;
  display: flex;
  overflow: hidden;
  width: 100%;
}

.tabsContent {
  background: var(--bg-tertiary);
  border: var(--border-less-primary);
  position: relative;
  width: 100%;

  @media (max-width: 960px) {
    display: none;
  }
}

.placeOfferContainer {
  cursor: default;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 24px 24px;

  max-width: 432px;
  width: 100%;

  @media (max-width: 960px) {
    padding: 8px 16px 16px;
    max-width: unset;
  }
}

.showOffersMobileButton {
  max-width: 96px;
  margin-bottom: 8px;

  @media (min-width: 961px) {
    display: none;
  }
}

.modal :global {
  .ant-modal-body {
    height: 440px;
  }

  .ant-modal-content {
    background: var(--bg-tertiary);
    padding: 0;
    overflow: hidden;
  }
}
