.wrap {
  backdrop-filter: blur(5px);
}

.modal :global {
  padding-bottom: 0;

  .ant-modal-content {
    background: var(--bg-primary);
    border: var(--border-less-primary);
    border-radius: 6px;
    box-shadow: none;
    color: var(--content-primary);
    padding: 24px;

    @media (max-width: 640px) {
      padding: 16px;
    }
  }

  /* Styles for the close icon */
  .ant-modal-close {
    top: -48px;
    inset-inline-end: 0;

    @media (max-width: 960px) {
      right: 24px;
    }

    @media (max-width: 640px) {
      top: -48px;
      right: 16px;
    }
  }

  .ant-modal-close:hover {
    background: unset;

    /* Styling border in close icon on hover */
    svg rect:last-child {
      stroke: var(--content-primary);
    }
  }

  .ant-modal-close,
  .ant-modal-close svg {
    height: 32px;
    width: 32px;
  }

  .ant-modal-close svg path {
    stroke: var(--content-primary);
  }

  .ant-modal-close svg rect:first-child {
    fill: var(--bg-primary);
  }

  @media (max-width: 960px) {
    position: absolute;
    top: unset !important;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    max-width: unset;
    width: 100% !important;
  }
}
