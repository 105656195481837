.tableRoot {
  width: 100%;

  @media (max-width: 1240px) {
    min-width: 490px;
  }

  tr > td,
  tr > th {
    @media (max-width: 1240px) {
      width: 68px;

      &:first-child,
      &:nth-child(4),
      &:last-child {
        width: 118px;
      }
    }
  }

  td {
    font: var(--body-text-sm);
    padding: 4px !important;

    &:first-child {
      padding-left: 16px !important;
    }

    &:last-child {
      padding-right: 16px !important;
    }
  }

  th {
    background: var(--bg-secondary);

    & > div {
      font: var(--important-text-xs);
    }
  }
}

.tableWrapper {
  border-bottom: var(--border-less-primary);
}

.cellText {
  &,
  a {
    font: var(--body-text-sm);
  }
}
