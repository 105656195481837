.sortWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  gap: 16px;

  @media (max-width: 960px) {
    padding: 8px 16px;
  }
}

.rowGap {
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 960px) {
    gap: 8px;
  }
}

.filters {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.searchWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
