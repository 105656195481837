.orderBook {
  display: flex;
  flex-direction: column;
  padding: 16px 0 32px;
  height: 100%;

  @media (max-width: 960px) {
    padding: 16px 0 0;
    height: 408px;
  }
}

.labelsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 64px 0 24px;
  width: 100%;

  @media (max-width: 640px) {
    padding: 0 56px 0 16px;
  }
}

.labelWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  width: calc(100% / 3);

  &:nth-child(2) {
    justify-content: center;
  }

  &:last-child {
    justify-content: flex-end;
  }

  @media (max-width: 640px) {
    width: 100%;
  }
}

.label {
  color: var(--content-secondary);
  font: var(--important-text-xs);
  text-transform: uppercase;
  white-space: nowrap;
}

.offersList {
  margin-top: 8px;
  overflow-y: auto;
}
