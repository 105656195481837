//? ### ManageModal
.modal :global {
  z-index: 2;

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    padding: 0;

    @media (min-width: 961px) {
      // min-height: 336px; //? min-height for modal with tabs enabled
      min-height: 240px;
    }
  }

  .ant-modal-content {
    padding: 0;
  }
}
.tabs {
  flex-wrap: nowrap;
}
.modalContent {
  padding: 24px;
  color: var(--content-primary);

  @media screen and (max-width: 960px) {
    padding: 18px;
  }
}

.freezeTimerWrapper {
  background: var(--additional-blue-secondary);
  border: 1px solid var(--additional-blue-primary);

  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 16px;
  margin: 24px;
  font: var(--body-text-md);

  @media (max-width: 640px) {
    margin: 16px;
    font: var(--body-text-sm);
  }
}
//? ### ManageModal

//? ### Repaiment content
.repaymentCallInfo {
  display: flex;
  justify-content: flex-start;
}
.repaimentCallAdditionalInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 32px 0 8px;
  margin-bottom: 12px;
}
.repaymentCallButton {
  width: 100%;
}
//? ### Repaiment content

//? ### Closure content
.closureContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
}
.twoColumnsContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}
.closureTexts {
  h3 {
    font: var(--title-lg);
    margin-bottom: 0;
    text-transform: uppercase;
  }
  p {
    font: var(--body-text-md);
    @media (max-width: 640px) {
      font: var(--body-text-sm);
    }
  }
}
.exitValue {
  display: flex;
  align-items: center;
}
.terminateButton {
  &:not(&:disabled) {
    background-color: var(--additional-red-primary-sub) !important;

    &:hover {
      border: 1px solid var(--additional-red-primary) !important;
    }
  }
}
//? ### Closure content
