// * WalletAccountSidebar * //
.walletSidebarContainer {
  background: var(--bg-tertiary);

  display: flex;
  flex-direction: column;
  width: 328px;
  height: 100%;

  position: fixed;
  top: 0;
  right: 0;
  z-index: var(--z-sidebar);

  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;

  &.visible {
    transform: translateX(0);
  }

  @media (max-width: 640px) {
    width: 312px;
  }
}

.sidebarOverlay {
  background: var(--overlay-bg);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-overlay);

  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }
}
// * WalletAccountSidebar * //

// * AccountOverview * //
.accountOverviewContainer {
  position: relative;
  padding: 0 24px 24px;
  width: 100%;

  @media (max-width: 640px) {
    padding: 0 16px 16px;
  }
}

.closeIcon {
  display: none;

  @media (max-width: 640px) {
    background: var(--bg-primary);
    border: var(--border-less-primary);
    border-radius: 100px;
    cursor: pointer;

    display: none;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 16px;
    left: -40px;
    z-index: var(--z-sidebar);

    min-height: 32px;
    min-width: 32px;

    &.visible {
      display: flex;
    }

    svg {
      width: 12px;

      stroke: var(--content-primary);

      rect {
        fill: var(--content-primary);
      }
    }
  }
}

.accountDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.disconnectButton {
  cursor: pointer;
  font: var(--btn-text-md);

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 640px) {
    font: var(--btn-text-sm);
  }

  svg {
    height: 24px;
    width: 24px;

    path {
      fill: var(--content-primary);
    }
  }
}
// * AccountOverview * //

// * WalletDetails * //
.walletDetailsContainer {
  background: var(--bg-primary);
  margin: 0 -24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 640px) {
    margin: 0 -16px;
  }
}

.walletDetailsTitle {
  font: var(--title-md);
  text-transform: uppercase;
}

.walletDetailsSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.walletDetailsContent {
  display: flex;
  align-items: center;
  gap: 8px;
}

.userAvatar {
  &,
  svg,
  img {
    height: 40px !important;
    width: 40px !important;
  }
}

.walletDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.walletAddressSection {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;

  svg path {
    fill: var(--content-primary);
  }
}

.walletAddressText {
  color: var(--content-primary);
  font: var(--btn-text-md);
}

.ledgerCheckbox label {
  font: var(--body-text-md);
}
// * WalletDetails * //

// * SidebarFooter *//
.sidebarFooter {
  border-top: var(--border-less-primary);
  background: var(--bg-tertiary);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;

  position: absolute;
  bottom: 0;
  width: 100%;

  @media (max-width: 640px) {
    padding: 12px 16px;
  }
}

.settingsButton {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font: var(--btn-text-md);
  }
}

.communityLinks {
  display: flex;
  align-items: center;
  gap: 16px;

  a {
    text-decoration: none;
    width: 16px;
    height: 16px;

    &:nth-child(3) {
      svg path:not(:first-child) {
        fill: var(--bg-tertiary);
      }
    }
  }

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: var(--content-primary);
    }

    &:hover {
      path {
        fill: var(--content-secondary);
      }
    }
  }
}
// * SidebarFooter *//

// * WalletList * //
.walletListContainer {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 12px;
}

.walletListTitle {
  font: var(--title-md);
  text-transform: uppercase;
}

.walletGrid {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.walletListItem {
  background: var(--action-primary);
  border-radius: 6px;
  cursor: pointer;

  font: var(--btn-text-md);

  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;

  &:hover {
    background: var(--action-secondary);
  }
}

.customWalletIcon,
.walletImage {
  border-radius: 4px;
  width: 24px;
  height: 24px;
}
// * WalletList * //

// * WalletAssets * //
.walletAssetsContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 56px;
}
// * WalletAssets * //

// * TokenListItem * //
.tokensListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;

  &:hover,
  &:nth-child(odd) {
    background: var(--bg-secondary);
  }

  &MainInfo {
    display: flex;
    flex-direction: column;
  }

  &Info {
    display: flex;
    padding-left: 16px;
    gap: 8px;

    @media (max-width: 640px) {
      padding-left: 8px;
    }
  }

  &Icon {
    border-radius: 100px;
    height: 30px;
    width: 30px;
  }

  &Ticker {
    color: var(--content-primary);
    font: var(--important-text-sm);
  }

  &Address {
    color: var(--content-secondary);
    font: var(--body-text-xs);
  }

  &BalanceInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 16px;

    @media (max-width: 640px) {
      padding-right: 8px;
    }
  }

  &CollateralsAmount {
    font: var(--body-text-sm);
  }

  &CollateralsAmountUsd {
    font: var(--body-text-xs);
  }
}
// * TokenListItem * //
