.mainSummary {
  background: var(--action-tertiary);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 16px;
  gap: 16px;
  padding: 8px;
  width: 100%;

  @media (max-width: 640px) {
    margin-top: 8px;
    padding: 4px;
  }
}

.mainSummaryStat {
  align-items: center;
  width: 100%;
}

.separateLine {
  background: var(--bg-border);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 1px;
}

.additionalSummary {
  border: 1px dotted var(--bg-border);
  border-left: 0;
  border-right: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 24px;
  margin: 12px -24px;

  @media (max-width: 640px) {
    padding: 8px 16px;
    margin: 8px -16px;
  }
}

.fixedValueContent {
  max-width: 86px;
  white-space: nowrap;
  overflow: auto;

  @media (max-width: 960px) {
    max-width: 144px;
  }
}

.aprValue {
  color: var(--additional-green-primary-deep);
}
