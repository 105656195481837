.pageWrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  margin: 24px auto 0;
  gap: 24px;
  max-width: 960px;
  width: 100%;

  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    overflow: hidden;
  }

  @media (max-width: 640px) {
    margin: 0;
  }
}

.content {
  background-color: var(--bg-primary);
  border-radius: 12px;
  padding: 24px 0;

  max-width: 392px;
  width: 100%;

  @media (max-width: 960px) {
    border: 0;
    padding: 16px 0 0;
  }

  @media (max-width: 640px) {
    max-width: unset;
  }
}

.controlBtns {
  display: flex;
  justify-content: space-between;
  margin: 0 16px 24px;
}

.slippageBtn {
  svg path {
    fill: var(--content-primary);
  }
}

.tokenDropdownWrapper {
  display: flex;
  margin: 0 16px 8px;
}

.borrowInput {
  margin: 0 16px 4px;
}

.collateralPrice {
  font: var(--body-text-sm);
  margin: 0 16px;
  text-align: right;
}

.footerContent {
  border-top: 1px dotted var(--bg-border);

  display: flex;
  flex-direction: column;
  padding: 12px 24px 0;
  gap: 16px;

  @media (max-width: 960px) {
    background: var(--bg-primary);

    padding: 8px 16px 16px;
    margin: 0;
    gap: 8px;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}

.depositButton {
  width: 100%;
}

.faqContainer {
  margin: 24px auto 0;
  max-width: 960px;
  width: 100%;

  @media (max-width: 960px) {
    display: none;
  }
}
