// * ManageModal * //
.modal :global {
  z-index: 2;

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    padding: 0;

    @media (min-width: 961px) {
      // min-height: 336px; //? min-height for modal with tabs enabled
      min-height: 240px;
    }
  }

  .ant-modal-content {
    background-color: var(--bg-tertiary);
    padding: 0;
  }
}

.tabs {
  flex-wrap: nowrap;
}
// * ManageModal * //

.freezeTimerWrapper {
  background: var(--additional-blue-secondary);
  border: 0.5px solid var(--additional-blue-primary);
  border-radius: 6px;

  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 16px;
  height: 24px;

  @media (max-width: 640px) {
    font: var(--body-text-sm);
  }
}

// * RepaymentCallContent * //
.modalContent {
  padding: 24px;
  color: var(--content-primary);

  @media screen and (max-width: 960px) {
    padding: 18px;
  }
}

.repaymentCallInfo {
  display: flex;
  justify-content: flex-start;
}
.repaimentCallAdditionalInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 32px 0 8px;
  margin-bottom: 12px;
}
.repaymentCallButton {
  width: 100%;
}
// * RepaymentCallContent * //

// * ClosureContent * //
.closureContent {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;

  @media (max-width: 640px) {
    padding: 16px;
  }
}

.closureContentInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.closureContentTexts {
  h3 {
    font: var(--title-lg);
    margin-bottom: 2px;
    text-transform: uppercase;

    @media (max-width: 640px) {
      font: var(--title-md);
    }
  }

  p {
    font: var(--body-text-sm);

    @media (max-width: 640px) {
      font: var(--body-text-xs);
    }
  }
}

.actionButton {
  min-width: 128px;

  @media (max-width: 640px) {
    min-width: 104px;
  }
}

.skeletonButton {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.1) !important;
  min-width: 128px !important;

  @media (max-width: 640px) {
    min-width: 104px !important;
  }
}

.exitValue {
  display: flex;
  align-items: center;
}

.terminateButton {
  &:not(&:disabled) {
    background-color: var(--additional-red-primary-sub) !important;

    &:hover {
      border: 1px solid var(--additional-red-primary) !important;
    }
  }
}
// * ClosureContent * //
