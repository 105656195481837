// * LendVaultPage * //
.pageWrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
}

.pageContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px;

  @media (max-width: 1180px) {
    padding: 16px 0;
  }
}

.statsPanel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;

  @media (max-width: 1180px) {
    flex-direction: column;
  }
}

.contentPanels {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  width: 100%;

  @media (max-width: 1180px) {
    flex-direction: column-reverse;
    gap: 16px;
  }
}

.vaultViewPanel {
  display: flex;
  flex-direction: column;
  height: 252px;
  width: 100%;
}
// * LendVaultPage * //

// * MainStats * //
.mainStats {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: 100%;

  @media (max-width: 1180px) {
    border-bottom: var(--border-less-primary);
    padding-bottom: 16px;
    margin-bottom: 0;
  }
}

.mainStat {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  @media (max-width: 1180px) {
    align-items: center;
  }

  @media (max-width: 640px) {
    gap: 4px;
  }
}

.mainStatLabel {
  color: var(--content-tertiary);
  font: var(--important-text-sm);

  @media (max-width: 640px) {
    font: var(--important-text-xs);
  }
}

.mainStatValue {
  color: var(--content-tertiary);
  font: var(--important-text-3xl);

  @media (max-width: 1180px) {
    font: var(--important-text-2xl);
  }

  @media (max-width: 640px) {
    font: var(--important-text-md);
  }
}
// * MainStats * //

// * UserStats * //
.userStats {
  border: var(--border-less-primary);
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 12px 24px;
  max-width: 424px;
  width: 100%;
  height: 100%;

  @media (max-width: 1180px) {
    border: 0;
    border-radius: 0;
    border-bottom: var(--border-less-primary);
    padding: 16px;
    max-width: unset;
  }
}

.userStatLabel {
  color: var(--content-tertiary);
  font: var(--important-text-sm);
  text-transform: uppercase;

  @media (max-width: 640px) {
    font: var(--important-text-xs);
  }
}

.depositUserStat {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.userStatSeparator {
  background: var(--bg-border);
  width: 0.5px;
  height: calc(100% + 24px);

  @media (max-width: 1180px) {
    height: calc(100% + 32px);
  }
}

.withdrawUserStat {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  width: 100%;
}
// * UserStats * //

// * VaultTabsContent * //
.vaultTabsContent {
  margin-top: 24px;
  height: 100%;

  @media (max-width: 1180px) {
    margin-top: 0;
    padding: 16px;
  }

  @media (max-width: 640px) {
    padding-bottom: 0;
  }
}

.vaultTabs {
  justify-content: flex-start;
  margin-bottom: 12px;
  width: max-content;
}
// * VaultTabsContent * //
