.root {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
}

.disabled {
  cursor: default;

  .label {
    color: var(--content-secondary);
  }

  .slider::before {
    background: var(--content-secondary);
  }
}

.input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.slider {
  display: inline-block;
  width: 44px;
  height: 22px;
  position: relative;
  background: var(--action-primary);
  border-radius: 16px;

  &::before {
    content: '';
    background: var(--pure-white);
    border-radius: 50%;
    box-shadow: 0px 2px 4px 0px #00230b33;

    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 1;

    height: 18px;
    width: 18px;
  }
}

.input:checked + .slider {
  background: var(--accent-primary);
}

input:checked + .slider:before {
  transform: translateX(22px);
}

.label {
  color: var(--content-primary);
  font: var(--body-text-md);
  white-space: nowrap;
}
