.editSummary {
  border-top: var(--border-less-primary);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 8px;
  gap: 16px;

  & > div {
    flex-direction: column-reverse;
    align-items: center;
    gap: 0;
  }

  @media (max-width: 640px) {
    white-space: nowrap;
    gap: 8px;
  }
}

.fields {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin-top: 16px;
  flex-wrap: wrap;

  @media (max-width: 640px) {
    flex-wrap: nowrap;
    margin-top: 8px;
    gap: 8px;
  }
}
.maxOfferInput {
  max-width: 138px;
}
.offersAmountInput {
  max-width: 146px;
}

.messageContainer {
  height: 14px;
}

// OffetActionButtons
.actionsButtonsContainer,
.editModeButtonsContainer {
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 640px) {
    gap: 8px;
  }
}
.editModeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}
.actionButton {
  width: 144px;

  @media (max-width: 640px) {
    width: 100%;
  }
}
.placeOfferButton {
  width: 100%;
}

.removeOfferButton {
  background-color: var(--additional-red-primary-sub) !important;

  &:hover {
    border: 1px solid var(--additional-red-primary) !important;
  }
}
