.wrapper {
  padding: 4px 32px;
  background: var(--accent-primary);
  color: var(--content-primary);
  display: flex;
  font-family: var(--font-family-wix);
  justify-content: flex-end;
  align-items: center;
  column-gap: 8px;
  z-index: 99;

  &__hidden {
    display: none;
  }

  @media screen and (max-width: 1240px) {
    padding: 12px 24px;
  }

  @media screen and (max-width: 960px) {
    padding: 12px 16px;
  }
}

.content {
  flex-grow: 1;
  color: var(--pure-black);
}

.closeIcon {
  min-height: 32px;
  min-width: 32px;
  border: var(--border-less-primary);
  background: var(--bg-primary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -1px;
  cursor: pointer;

  svg {
    width: 12px;

    stroke: var(--content-primary);

    rect {
      fill: var(--content-primary);
    }
  }
}
