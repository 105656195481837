.pageWrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 48px 0;

  @media (max-width: 1240px) {
    padding: 24px 0;
  }
}

.mainSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  @media (max-width: 1240px) {
    flex-direction: column;
  }
}

.mainSectionTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font: var(--title-3xl);
    text-transform: uppercase;
    margin-bottom: 12px;

    @media (max-width: 1240px) {
      font: var(--title-2xl);
    }

    @media (max-width: 640px) {
      font: var(--title-xl);
    }
  }

  p {
    font: var(--body-text-xl);

    @media (max-width: 1240px) {
      font: var(--body-text-lg);
    }

    @media (max-width: 640px) {
      font: var(--body-text-md);
    }
  }
}

.tokensList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 24px;
  margin-top: 24px;

  @media (max-width: 640px) {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 8px;
  }
}

.tokenItem {
  color: var(--content-primary);
  cursor: pointer;
  overflow: hidden;
  border-radius: 12px;
  background: var(--bg-primary);
  text-decoration: none;

  &:hover {
    box-shadow: 0 2px 0 var(--action-primary);
  }

  &Disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &Header {
    padding: 12px;
    display: flex;
    column-gap: 8px;
    align-items: center;
    background: var(--action-primary);
    height: 56px;

    @media (max-width: 640px) {
      height: 42px;
      padding: 8px;
    }

    img {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      object-fit: cover;

      @media (max-width: 640px) {
        width: 24px;
        height: 24px;
      }
    }
  }
  &Ticker {
    text-transform: uppercase;
    font: var(--title-md);

    @media (max-width: 640px) {
      font: var(--title-sm);
    }
  }
  &ApyWrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &ApyTitle {
    display: flex;
    align-items: center;
    gap: 2px;

    font: var(--important-text-xs);
    color: var(--content-tertiary);
    text-transform: uppercase;
    margin-bottom: 4px;

    @media (max-width: 640px) {
      margin-bottom: 0;
    }
  }
  &ApyValue {
    font: var(--important-text-sm);

    @media (max-width: 640px) {
      font: var(--important-text-xs);
    }
  }
  &Footer {
    padding: 6px 12px;
  }
  &Multiply,
  &MultiplyPlaceholder {
    font: var(--important-text-sm);
    text-transform: uppercase;

    @media (max-width: 640px) {
      font: var(--important-text-xs);
    }
  }

  &Multiply {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
