// * OfferCard * //
.card {
  background: var(--bg-primary);
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
}

.placeOfferContent {
  cursor: default;
  padding: 0 24px 24px;
  width: 100%;

  @media (max-width: 640px) {
    padding: 0 16px 8px;
  }
}

.cardBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 10px 24px;
  width: 100%;

  &:hover:not(.opened) {
    background: var(--action-tertiary);
    border-radius: 6px;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 16px;
  }

  @media (max-width: 640px) {
    padding: 8px 16px;

    &.opened {
      padding-bottom: 0;
    }
  }
}

.additionalContentWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
}

.chevronButton {
  display: flex;

  &.opened svg {
    transform: rotate(180deg);
  }

  @media (max-width: 960px) {
    position: absolute;
    top: 8px;
    right: 16px;
  }
}

// * OfferCard * //

// * MarketMainInfo * //
.mainInfoContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  max-width: 290px;
  width: 100%;

  @media (min-width: 961px) and (max-width: 1240px), (max-width: 640px) {
    max-width: 176px;
  }

  @media (max-width: 960px) {
    gap: 8px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.collectionImage {
  border-radius: 6px;
  height: 30px;
  width: 30px;

  @media (max-width: 640px) {
    width: 24px;
    height: 24px;
  }
}

.collectionName {
  font: var(--important-text-md);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
// * MarketMainInfo * //

// * MarketAdditionalInfo * //
.additionalInfoStats {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  white-space: nowrap;
  width: 100%;

  @media (max-width: 960px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 8px;
  }
}

.additionalInfoStat {
  align-items: flex-end;
  gap: 2px;
  max-width: 180px;
  width: 100%;

  @media (max-width: 1240px) {
    max-width: 120px;
  }

  @media (max-width: 960px) {
    .opened &:nth-child(n + 3) {
      visibility: hidden;
      height: 0;
    }

    &:nth-child(-n + 2) {
      grid-row: 1;
    }

    &:nth-child(n + 3) {
      grid-row: 2;
    }

    &:not(:last-child) {
      align-items: flex-start;
    }
  }
}

.additionalInfoStatLabelWrapper {
  @media (min-width: 961px) {
    display: none;
  }
}
// * MarketAdditionalInfo * //

.actionsOfferButtons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.actionButton {
  &:disabled {
    background: var(--bg-tertiary);

    path,
    rect {
      fill: var(--content-secondary);
    }
  }

  @media (max-width: 640px) {
    min-height: 24px !important;
    max-height: 24px !important;
    min-width: 24px !important;
    max-width: 24px !important;
  }
}

.removeOfferButton {
  svg {
    height: 12px !important;
    width: 12px !important;

    rect {
      fill: var(--content-primary);
    }
  }
}
