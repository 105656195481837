.container {
  cursor: default;
  display: flex;
  overflow: hidden;
  height: 370px;
  width: 100%;

  @media (max-width: 640px) {
    height: 302px;
  }
}

.tabsContent {
  background: var(--bg-tertiary);
  border: var(--border-less-primary);
  width: 100%;

  @media (max-width: 960px) {
    display: none;
  }
}

.modal :global {
  .ant-modal-body {
    height: 440px;
  }

  .ant-modal-content {
    background: var(--bg-tertiary);
    padding: 0;
    overflow: hidden;
  }
}

.content {
  padding: 12px 24px 12px;
  cursor: default;
  min-width: 344px;
  max-width: 344px;

  @media (max-width: 960px) {
    max-width: unset;
    width: 100%;
  }

  @media (max-width: 640px) {
    padding: 8px 16px 8px;
  }
}

.offerHeaderContent {
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 640px) {
    margin-bottom: 0;
    gap: 8px;
  }
}
.offerHeaderTitle {
  font: var(--title-lg);
  text-transform: uppercase;
  line-height: 38px;

  @media (max-width: 640px) {
    font-size: 12px;
    line-height: 32px;
  }
}

.offersButton {
  margin-left: auto;

  @media (min-width: 961px) {
    display: none !important;
  }
}

.editButton,
.offersButton {
  width: 104px;

  @media (max-width: 640px) {
    width: 80px;
  }
}
