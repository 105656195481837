.modal :global {
  z-index: 2;

  .ant-modal-body {
    padding: 0 !important;
  }

  .ant-modal-content {
    padding: 0;
  }
}

.container {
  padding: 12px 24px 24px;
}

.field {
  display: flex;
  flex-direction: column;
  margin: 8px 0 12px;
}

.input {
  background: var(--action-primary);
  border: 1px solid var(--bg-border);
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding-right: 4px;

  &:has(input:focus) {
    background: var(--action-secondary);
  }
}

.maxButton {
  background-color: var(--action-secondary) !important;
}

.content {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  gap: 8px;
  overflow: hidden;
}

.statInfo {
  display: flex;
  align-items: center;
  gap: 4px;

  .label {
    color: var(--content-secondary);
    font: var(--important-text-sm);
    text-transform: uppercase;

    @media (max-width: 640px) {
      font: var(--important-text-xs);
    }
  }

  .value {
    font: var(--body-text-md);

    @media (max-width: 640px) {
      font: var(--body-text-sm);
    }
  }

  &.spaceBetween {
    justify-content: space-between;
  }

  //? Prevent bug with BANX icon on dark theme
  svg path {
    fill: #1b1b1b;
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 4px;
}

.stakeContainer {
  display: flex;
  border: 0.5px dashed var(--bg-border);
  flex-direction: column;
  align-items: center;
  padding: 12px;
  gap: 12px;

  .title {
    display: flex;
    align-items: center;
    gap: 4px;
    font: var(--important-text-sm);
    text-transform: uppercase;
  }

  .valuesRaw {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
  }
}

.banxPointsStats {
  display: flex;
  align-items: center;
  gap: 12px;
}

.banxPointsStat {
  flex-direction: column-reverse;
  align-items: center;
  gap: unset;

  .value {
    font: var(--important-text-xl);
  }

  .label {
    color: var(--content-primary);
  }
}

.stakeButton,
.unstakeButton {
  width: 100%;
}
