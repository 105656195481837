// * LendVaultsPage * //
.pageWrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;

  @media (min-width: 961px) {
    position: relative;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.vaultsList {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 8px;
  gap: 8px;
}
// * LendVaultsPage * //

// * HeaderList * //
.headerList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  width: 100%;

  @media (max-width: 960px) {
    display: none;
  }
}

.headerMainStat {
  display: flex;
  min-width: 290px;

  @media (min-width: 961px) and (max-width: 1240px) {
    min-width: 176px;
  }
}

.headerStatLabel {
  color: var(--content-tertiary);
  font: var(--important-text-sm);
  text-transform: uppercase;
}

.headerStats {
  display: flex;
  justify-content: flex-end;
  padding-right: 104px;
  gap: 16px;
  width: 100%;
  white-space: nowrap;
}

.headerAdditionalStat {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  max-width: 180px;
  width: 100%;

  @media (max-width: 1240px) {
    max-width: 120px;
  }
}
// * HeaderList * //

// * FilterSection * //
.filterSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  gap: 16px;
  width: 100%;

  @media (max-width: 960px) {
    padding: 8px 16px;
  }
}

.filterContent {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}
// * FilterSection * //

// * UserDepositFilterButton *//
.filterButtons {
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: 960px) {
    gap: 16px;
  }
}

.filterButtonWrapper {
  position: relative;
  width: max-content;

  &[data-amount]::after {
    border-radius: 100px;
    content: attr(data-amount);
    color: var(--pure-black);
    font: var(--body-text-xs);

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;

    position: absolute;
    top: -2px;
    right: -8px;
    min-width: 8px;
    height: 16px;
  }

  &.userDeposit[data-amount]::after {
    background: var(--additional-yellow-primary);
  }
}

.userDepositFilterButton {
  font: var(--body-text-md) !important;
  padding: 2px 8px !important;
  gap: 4px !important;

  &.active {
    border-color: var(--content-primary);
  }

  &.disabled {
    svg path:nth-child(-n + 5) {
      fill: var(--content-secondary);
    }
  }

  svg path:nth-child(-n + 5) {
    fill: var(--additional-yellow-primary);
  }

  svg path:nth-last-child(-n + 2) {
    fill: var(--pure-black);
  }
}
// * UserDepositFilterButton *//
