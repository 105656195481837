.modal {
  :global {
    z-index: 2;

    .ant-modal-body {
      padding: 0 !important;
      // margin: -1px;
    }

    .ant-modal-content {
      padding: 0;
    }
  }
}

.content {
  padding: 24px;
  color: var(--content-primary);
  min-height: 470px;

  @media screen and (max-width: 960px) {
    padding: 18px;
    min-height: 448px;
  }

  @media screen and (max-width: 640px) {
    padding: 16px;
  }
}

.stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 18px;
}

.stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 18px;
}

.statsCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  p:first-child {
    font: var(--important-text-xl);
    @media screen and (max-width: 640px) {
      font: var(--important-text-lg);
    }
  }

  p:last-child {
    font: var(--important-text-sm);
    text-transform: uppercase;
    @media screen and (max-width: 640px) {
      font: var(--important-text-xs);
    }
  }
}

.nfts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  overflow-y: scroll;
  max-height: 360px;
  padding-bottom: 92px;
}

.noNfts {
  height: 360px;
  padding-bottom: 92px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 16px;

  p {
    text-align: center;
    font: 400 24px var(--body-text-lg);
  }
}
.tensorBtn {
  display: flex;
  align-items: center;
  column-gap: 8px;
  position: relative;
  a {
    position: absolute;
    inset: 0 0 0 0;
  }
}

.nft {
  user-select: none;
  border: 1px solid var(--bg-border);

  p {
    text-align: center;
    font: var(--body-text-md);
    padding: 8px 0;
  }
}
.nftPointer {
  cursor: pointer;
}
.nftDisabled {
  cursor: not-allowed;
  pointer-events: none;
  position: relative;

  .cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 34px;
    background: #000000b0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: var(--pure-white);
      font: var(--important-text-xl);
      text-align: center;
    }
  }
}

.image {
  position: relative;
  background: lightgray 50%;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.selected {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #000000b0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    width: 20%;
    height: 3%;
    background: #fff;
    border-radius: 10%;
  }
}

.additionalText {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000b0;
  color: #fff;
  font: var(--important-text-xl);
  text-align: center;

  @media (max-width: 640px) {
    font: var(--important-text-lg);
  }
}

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 24px;
  background-color: var(--bg-primary);
  border-top: 1px solid var(--bg-border);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  z-index: 999;
}

.footerEmpty {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 24px;
  background-color: var(--bg-primary);
  border-top: 1px solid var(--bg-border);
  z-index: 999;
}

.footerBtn {
  padding: 10px;
}

.tabs {
  flex-wrap: nowrap;
}

.emptyNfts {
  width: 100%;
  text-align: center;
  font: var(--important-text-xl);
  font-weight: 400;
  margin-top: 130px;
}

.footerTensorBtn {
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }

  & > a {
    display: flex;
    align-items: center;
    color: inherit;
  }
}
