@import './base/reset.less';
@import './base/scrollBar.less';
@import './abstracts/fonts.less';
@import './abstracts/variables.less';

@import './antd/table.less';
@import './antd/slider.less';
@import './antd/tooltip.less';

body {
  background-color: var(--bg-tertiary);
  color: var(--content-primary);
  font: var(--body-text-md);
}
