.earnTabContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 24px;
  width: 100%;

  @media (max-width: 960px) {
    flex-direction: column;
  }

  @media (max-width: 640px) {
    padding: 16px;
  }
}

.contentBlockContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 100%;

  @media (max-width: 640px) {
    gap: 16px;
  }
}

.titleBlockWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  @media (max-width: 640px) {
    flex-direction: row;
    width: 100%;
  }
}
.titleBlock {
  font: var(--title-xl);
  text-transform: uppercase;

  @media (max-width: 640px) {
    font: var(--title-lg);
  }
}
.titleBlockIcon {
  @media (max-width: 640px) {
    height: 24px;
    width: 24px;
  }
}

.infoBlock {
  border: var(--border-less-primary);
  border-style: dashed;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  width: 100%;
  height: 100%;
}

.infoRow {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: auto;

  svg {
    min-width: 24px;
  }
}

.actionButton {
  &,
  button {
    text-decoration: none;
    width: 100%;
  }
}

.emptyList {
  @media (max-width: 640px) {
    margin: 16px;
  }

  div {
    text-align: left;
  }
}
