// * LeveragePositionCard * //
.card {
  background: var(--bg-primary);
  border-radius: 6px;
  width: 100%;

  &:hover {
    background: var(--action-tertiary);
  }
}

.cardBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 10px 24px;
  width: 100%;

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 16px;
  }
}

.additionalContentWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
}

.exitButton {
  display: flex;
  min-width: 64px;

  @media (max-width: 960px) {
    position: absolute;
    top: 8px;
    right: 16px;
  }
}
// * LeveragePositionCard * //

// * PositionMainInfo * //
.mainInfoContainer {
  display: flex;
  align-items: center;
  gap: 12px;

  max-width: 290px;
  width: 100%;

  @media (min-width: 961px) and (max-width: 1240px), (max-width: 640px) {
    max-width: 176px;
  }

  @media (max-width: 960px) {
    gap: 8px;
  }
}

.mainInfoContent {
  display: flex;
  flex-direction: column;
}

.collateralPrice {
  display: flex;
  font: var(--body-text-md);

  @media (max-width: 640px) {
    font: var(--body-text-sm);
  }
}

.mainInfoContentRow {
  display: flex;
  align-items: center;
  gap: 2px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  color: var(--content-secondary);
  font: var(--important-text-md);

  @media (max-width: 640px) {
    font: var(--important-text-sm);
  }
}

.collateralImage {
  border-radius: 100%;
  height: 30px;
  width: 30px;

  @media (max-width: 640px) {
    height: 24px;
    width: 24px;
  }
}
// * PositionMainInfo * //

// * PositionAdditionalInfo * //
.additionalInfoStats,
.skeletonAdditionalInfoStats {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  white-space: nowrap;
  width: 100%;

  @media (max-width: 960px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 8px;
  }

  @media (max-width: 640px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 8px;
  }
}

.skeletonStat {
  span {
    height: 24px !important;
  }
}

.additionalInfoStat {
  align-items: flex-end;
  justify-content: center;
  gap: 2px;
  max-width: 180px;
  width: 100%;

  @media (max-width: 1240px) {
    max-width: 120px;
  }

  @media (max-width: 960px) {
    align-items: flex-start;
    &:last-child {
      align-items: flex-end;
    }
  }

  @media (max-width: 640px) {
    &:nth-child(3) {
      align-items: flex-end;
    }
  }
}

.additionalInfoStatLabelWrapper {
  @media (min-width: 961px) {
    display: none;
  }
}

.additionalAprStat {
  color: var(--additional-green-primary-deep);

  &.negative {
    color: var(--additional-red-primary-deep);
  }
}

.leverageStat {
  color: var(--additional-green-primary-deep);
}

.pnlStat {
  color: var(--additional-green-primary-deep);

  &.negative {
    color: var(--additional-red-primary-deep);
  }
}

// * PositionAdditionalInfo * //
