.pageWrapper {
  max-height: 100%;
  height: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow-y: hidden;

  @media screen and (max-width: 960px) {
    display: block;
  }
}

.loader {
  margin: 18px auto;
}

.content {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.adventuresList {
  padding: 12px 0;

  @media screen and (max-width: 640px) {
    padding: 8px 0;
  }
}

.adventuresListSidebarVisible {
  @media screen and (max-width: 1280px) {
    padding-bottom: calc(12px + 324px);
  }

  @media screen and (max-width: 640px) {
    padding-bottom: calc(8px + 253px);
  }
}

.sidebar {
  height: 100%;
  max-width: 344px;
  border-left: var(--border-less-primary);

  @media screen and (max-width: 1280px) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: unset;
    height: unset;
    border-left: unset;
    border-top: var(--border-less-primary);
  }

  @media screen and (max-width: 960px) {
    position: fixed;
  }
}
