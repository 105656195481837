.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.title {
  font: var(--title-xl);
  text-transform: uppercase;
  margin-bottom: 24px;
  text-align: center;

  @media screen and (max-width: 640px) {
    gap: 8px;
  }
}

.subtitle {
  color: var(--content-tertiary);
  font: var(--important-text-sm);
  text-transform: uppercase;
  margin-bottom: 8px;

  @media screen and (max-width: 640px) {
    font: var(--important-text-xs);
    margin-bottom: 6px;
  }
}

.tabs {
  background: var(--bg-tertiary);
  border: 1px solid var(--bg-border);
  border-radius: 6px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(72px, 1fr));
  margin-bottom: 24px;

  @media screen and (max-width: 640px) {
    margin-bottom: 16px;
  }
}

.tab {
  background: unset;
  border: unset;
  color: var(--content-secondary);
  cursor: pointer;
  font: var(--btn-text-md);

  padding: 7px 8px;
  outline: unset;
  text-align: center;

  &:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.tabActive {
  background: var(--accent-primary-sub);
  border: 1px solid var(--accent-primary);
  color: var(--content-primary);
  pointer-events: none;
  margin: -0.5px;
}

.themeContent {
  display: flex;
  flex-direction: column;
}

.saveButton {
  margin-top: 24px;
  width: 100%;
}
