.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  gap: 16px;
  width: 100%;

  @media (max-width: 960px) {
    padding: 8px 16px;
  }
}

.filterWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}
