.header {
  background: var(--bg-secondary);
  border-bottom: var(--border-less-primary);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  width: 100%;
  z-index: var(--z-header);

  @media (max-width: 960px) {
    padding: 8px 16px;
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
  height: 32px;
  min-width: 264px;

  svg path {
    fill: var(--content-primary);
  }

  .logo {
    display: none;
  }

  @media (max-width: 960px) {
    min-width: unset;

    .fullLogo {
      display: none;
    }

    .logo {
      display: flex;
      height: 32px;
      width: 32px;
    }
  }

  @media (max-width: 640px) {
    background: var(--action-primary);
    border-radius: 6px;
    padding: 8px 10px;

    .logo {
      height: 22px;
      width: 22px;
    }
  }
}

.widgetContainer {
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: 960px) {
    gap: 16px;
  }

  @media (max-width: 640px) {
    gap: 8px;
  }
}
