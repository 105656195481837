.solanaFMIcon {
  width: 100%;
  height: 100%;
}

.tensorLink,
.dexscreenerLink {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    fill: var(--content-secondary);
  }

  &:hover {
    svg path {
      fill: var(--content-primary);
    }
  }
}
