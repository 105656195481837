// * MarketBorrowCard * //
.card {
  background: var(--bg-primary);
  border-radius: 6px;
  width: 100%;
}

.cardBody {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 10px 24px;
  width: 100%;

  &:hover:not(.expanded) {
    background: var(--action-tertiary);
    border-radius: 6px;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 16px;
  }

  @media (max-width: 640px) {
    &.expanded {
      padding-bottom: 0;
    }
  }
}

.additionalContentWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
}

.expandButton {
  display: flex;

  &.expanded {
    background-color: var(--accent-secondary);

    svg {
      transform: rotate(180deg);
    }
  }
  @media (max-width: 960px) {
    position: absolute;
    top: 8px;
    right: 16px;
  }
}

.mainInfoContainer {
  display: flex;
  align-items: center;
  gap: 12px;

  max-width: 290px;
  width: 100%;

  @media (min-width: 961px) and (max-width: 1240px), (max-width: 640px) {
    max-width: 176px;
  }

  @media (max-width: 960px) {
    gap: 8px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.collateralImage {
  border-radius: 6px;
  height: 30px;
  width: 30px;

  @media (max-width: 640px) {
    height: 24px;
    width: 24px;
  }
}

.collateralName {
  font: var(--title-md);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
// * MarketBorrowCard * //

// * MarketBorrowCardInfo * //
.infoStats {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  white-space: nowrap;
  width: 100%;

  @media (max-width: 960px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 8px;
  }
}

.infoStat {
  align-items: flex-end;
  justify-content: center;
  gap: 0;
  max-width: 180px;
  width: 100%;

  @media (max-width: 1240px) {
    max-width: 120px;
  }

  @media (max-width: 960px) {
    align-items: flex-start;
    justify-content: flex-start;

    .expanded &:nth-child(n + 3) {
      visibility: hidden;
      height: 0;
    }

    &:nth-child(-n + 2) {
      grid-row: 1;
    }

    &:nth-child(n + 3) {
      grid-row: 2;
    }
  }
}

.infoStatLabelWrapper {
  @media (min-width: 961px) {
    display: none;
  }
}
// * MarketBorrowCardInfo * //
