//? Global sider styles
.sider {
  background-color: var(--bg-primary);
  right: 0;
  display: flex;
  flex-direction: column;

  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

.siderHidden {
  transform: translateX(100%);
}

.siderOverlay {
  background: var(--overlay-bg);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-overlay);

  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }
}

//? Sider header styles
.header {
  padding: 24px 24px 8px;
}

.headerTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerTitle {
  font: var(--title-md);
  text-transform: uppercase;
}

.clearNotificationsBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--content-primary);
  font: var(--btn-text-sm);
  background: unset;
  border: unset;
  cursor: pointer;
  padding: 0;

  svg {
    path {
      fill: var(--content-primary);
    }
  }
}

.content {
  padding: 12px 24px;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}
.contentCentered {
  justify-content: center;
  align-items: center;
}

.noNotificationsText {
  padding-top: 6px;
}

.contentTitle {
  font: var(--title-md);
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  margin-bottom: 16px;

  @media (max-width: 640px) {
    margin-bottom: 8px;
  }
}
.contentTitleSign {
  margin-bottom: 8px;
}

.contentTitleOffsetTop {
  padding-top: 24px;

  @media (max-width: 640px) {
    padding-top: 16px;
  }
}

.signMessageSubtitle {
  font: var(--body-text-lg);
  margin-bottom: 8px;
}

.signMessageButton {
  width: 100%;
}

.ledgerCheckbox {
  margin-bottom: 8px;
}

//? Notifications list
.notficationsList {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 16px;
}

.notificationCard {
  width: 100%;
  padding: 16px;
  border: 1px solid var(--bg-border);
}

.notificationCardInfo {
  display: flex;
  column-gap: 8px;
  font: var(--body-text-sm);
  margin-bottom: 8px;
  position: relative;
}
.notificationCardUnread {
  &::after {
    content: '';
    position: absolute;
    left: -5px;
    top: -6px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--additional-green-primary);
  }
}

.notificationCardImg {
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.notificationCardDate {
  color: var(--content-secondary);
  font: var(--body-text-xs);
}

.closeIcon {
  display: none;

  @media (max-width: 640px) {
    background: var(--bg-primary);
    border: var(--border-less-primary);
    border-radius: 100px;
    cursor: pointer;

    display: none;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 16px;
    left: -40px;
    z-index: var(--z-sidebar);

    min-height: 32px;
    min-width: 32px;

    &.visible {
      display: flex;
    }

    svg {
      width: 12px;

      stroke: var(--content-primary);

      rect {
        fill: var(--content-primary);
      }
    }
  }
}
