// * ListLoansContent * //
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px auto 0;
  gap: 24px;
  max-width: 960px;
  width: 100%;

  @media (max-width: 640px) {
    margin: 0;
  }
}

.content {
  background: var(--bg-primary);
  border-radius: 12px;
  padding: 24px;
  max-width: 392px;
  width: 100%;

  @media (max-width: 640px) {
    border-radius: 0;
    padding: 16px;
    max-width: unset;
  }
}

.faqContainer {
  @media (max-width: 960px) {
    display: none;
  }
}

.collateralInput {
  margin-bottom: 16px;
}

.borrowInput {
  margin-bottom: 16px;
}

.fields {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
}

.aprFieldWrapper {
  display: flex;
  flex-direction: column;
}

.actionButton {
  width: 100%;
}
// * ListLoansContent * //

// * Summary * //
.summary {
  border-top: 1px dotted var(--bg-border);
  display: flex;
  flex-direction: column;
  padding: 12px 24px 12px;
  margin: 0 -24px 0;
  gap: 8px;

  position: relative;

  @media (max-width: 640px) {
    padding: 8px 16px 8px;
    margin: 0 -16px 0;
    gap: 4px;
  }
}

.fixedStatValue {
  max-width: 74px;
  white-space: nowrap;
  overflow: auto;

  @media (max-width: 960px) {
    max-width: 144px;
  }
}
// * Summary * //

// * LenderAprMessage * //
.lenderAprMessageWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 14px;
}

.lenderAprMessage {
  font: var(--body-text-sm);

  @media (max-width: 640px) {
    font: var(--body-text-xs);
  }
}
// * LenderAprMessage * //
