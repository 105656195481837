.tableRoot {
  width: 100%;

  td {
    font: var(--body-text-sm);
    height: 38px;

    @media (max-width: 640px) {
      height: 32px;
    }

    &:first-child {
      padding-left: 16px !important;
    }

    &:last-child {
      padding-right: 16px !important;
    }
  }

  th {
    background: var(--bg-secondary);

    & > div {
      font: var(--important-text-xs);
    }
  }
}

.tableWrapper {
  border-bottom: var(--border-less-primary);
}

.cellText {
  &,
  a {
    font: var(--body-text-sm);
  }
}

.cellTextGreen {
  color: var(--additional-green-primary-deep);
}

.cellTextRed {
  color: var(--additional-red-primary-deep);
}
