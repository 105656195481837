// * LendVaultCard * //
.card {
  background: var(--bg-primary);
  border-radius: 6px;
  cursor: pointer;
  width: 100%;

  &:hover {
    background: var(--action-tertiary);
  }
}

.cardBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 16px 24px;
  width: 100%;

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 16px;
  }
}

.additionalContentWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.actionButton {
  width: 96px;

  @media (max-width: 960px) {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
// * LendVaultCard * //

// * VaultMainInfo * //
.mainInfoContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 290px;
  z-index: 1;

  @media (min-width: 961px) and (max-width: 1240px) {
    min-width: 176px;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  svg {
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
  }
}

.collateralName {
  font: var(--important-text-md);
}
// * VaultMainInfo * //

// * VaultAdditionalInfo * //
.additionalInfoStats {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  white-space: nowrap;
  width: 100%;

  @media (max-width: 960px) {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 8px;
  }
}

.additionalInfoStat {
  align-items: flex-end;
  justify-content: center;
  gap: 2px;
  max-width: 180px;
  width: 100%;

  @media (max-width: 1240px) {
    max-width: 120px;
  }

  @media (max-width: 960px) {
    align-items: flex-start;
  }
}

.additionalInfoStatLabelWrapper {
  @media (min-width: 961px) {
    display: none;
  }
}

.additionalApyStat {
  color: var(--additional-green-primary-deep);
}
// * VaultAdditionalInfo * //

// * AssetsLogos * //
.assetsLogosContainer {
  display: flex;
  align-items: center;
}

.assetLogo {
  border-radius: 100%;
  height: 24px;
  width: 24px;

  &.stackedLogo {
    margin-right: -8px;
  }
}

.extraAssetsCount {
  background-color: var(--additional-silver-secondary);
  border-radius: 100px;
  color: var(--additional-silver-primary);

  font: var(--body-text-sm);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
}

.assetsTooltipContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.assetTooltipItem {
  display: flex;
  align-items: center;
  gap: 8px;
}

.assetTooltipLogo {
  border-radius: 100%;
  height: 16px;
  width: 16px;
}

.assetTooltipTicker {
  font: var(--body-text-md);
}
// * AssetsLogos * //
