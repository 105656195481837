.tableWrapper {
  background-color: var(--bg-tertiary);
  height: 100%;
}

.borrowTable {
  min-width: 796px;

  tr > td,
  tr > th {
    border-radius: 0 !important;
    padding: 4px !important;

    &:first-child {
      padding-left: 16px !important;
      width: 220px;
    }
    &:last-child {
      padding-right: 16px !important;
      width: 144px;
    }
  }

  @media (max-width: 640px) {
    min-width: 614px;

    tr > td,
    tr > th {
      width: 96px;

      &:nth-child(3) {
        width: 110px;
      }

      &:first-child {
        width: 168px;
      }
    }
  }
}

.borrowButton {
  width: 104px;
}

.tooltipRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 230px;
}
.tooltipRowLabel {
  color: var(--content-secondary);
  font: var(--important-text-sm);
  text-transform: uppercase;

  @media (max-width: 640px) {
    font: var(--important-text-xs);
  }
}
.tooltipRowValue {
  font: var(--body-text-md);

  @media (max-width: 640px) {
    font: var(--body-text-sm);
  }
}

.headerTitleRow {
  display: flex;
  align-items: center;
  gap: 16px;
}

.headerCellText {
  font: var(--important-text-xs);
}

.bodyCellText {
  font: var(--body-text-sm);
}

.checkbox {
  height: 18px;
  padding-left: 12px;
}
