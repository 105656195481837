.rootTableClassName {
  .ant-table {
    background: var(--bg-primary);
    border: 0;
    border-radius: 0;
    color: var(--content-primary);
    overflow: auto;

    table {
      table-layout: auto !important;
    }
  }

  /* Sticky table header */
  .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  .ant-table-tbody > tr > td {
    border: 0;
    font: var(--body-text-md);
    vertical-align: middle;
  }

  .ant-table-tbody > tr:nth-child(even) {
    background: var(--bg-secondary);
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding: 8px;
    text-align: -webkit-right;
    transition: unset;
  }

  .ant-table-thead > tr > th:first-child,
  .ant-table-tbody > tr > td:first-child {
    padding-left: 24px;
    text-align: left;

    @media (max-width: 640px) {
      padding-left: 16px;
    }
  }

  .ant-table-thead > tr > th:last-child,
  .ant-table-tbody > tr > td:last-child {
    padding-right: 24px;

    @media (max-width: 640px) {
      padding-right: 16px;
    }
  }

  /* Styling for table header cells */
  .ant-table-thead > tr > th {
    background: var(--bg-primary);
    border-bottom: 0;
    color: var(--content-secondary);
    font: var(--title-md);
    text-transform: uppercase;
    white-space: nowrap;

    & > div {
      display: inline-flex;
      align-items: center;
    }
  }

  /* Remove sort arrows */
  .ant-table-column-sorter {
    display: none;
  }

  /* Remove separating line between cells */
  .ant-table-cell::before {
    display: none;
  }

  /* Remove background on cell hover */
  .ant-table-row:hover > td,
  .ant-table-cell-row-hover {
    background: var(--action-tertiary) !important;
  }

  /* Unset transition for table header cells with sorters */
  .ant-table-thead th.ant-table-column-has-sorters {
    transition: unset;
  }

  th.ant-table-column-sort,
  th.ant-table-column-has-sorters,
  td.ant-table-column-sort {
    background: var(--bg-primary) !important;
    cursor: default !important;
    transition: unset;
  }

  /* Hide the default empty content when the table is empty */
  .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    display: none;
  }
}
