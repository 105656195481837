// * RequestLoansTable *//
.tableRoot {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.table {
  min-width: 914px;

  tr > td,
  tr > th {
    &:last-child {
      width: 144px;
    }
  }

  @media (max-width: 640px) {
    min-width: 772px;

    tr > td,
    tr > th {
      width: 96px;

      &:first-child {
        width: 168px;
      }
    }
  }
}
// * RequestLoansTable *//

// * Summary * //
.summary {
  border-top: 1px solid var(--bg-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;

  @media (max-width: 1240px) {
    flex-direction: column;
    padding: 16px;
    gap: 8px;
  }
}

.statsContainer {
  display: flex;
  justify-content: space-around;
  gap: 24px;
  width: 100%;

  @media (max-width: 640px) {
    justify-content: unset;
    margin-right: -16px;
    padding-right: 16px;
    gap: 8px;
    overflow: auto hidden;
    width: calc(100% + 16px);
  }
}
.statsContainer > div {
  align-items: flex-end;
  gap: unset;

  @media (max-width: 1240px) {
    align-items: center;
    white-space: nowrap;
    width: 100%;
  }
}

.summaryControls {
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 1240px) {
    width: 100%;
  }

  @media (max-width: 640px) {
    gap: 8px;
  }
}

.delistAllButton {
  min-width: 128px;
}

.sliderContainer {
  @media (max-width: 1240px) {
    order: 2;
    flex-direction: row-reverse;
  }
}

.slider {
  padding-bottom: 18px;
}

.mainStat {
  display: flex;
  gap: 8px;
  align-items: center;
  min-width: 160px;

  @media (max-width: 1240px) {
    display: none;
  }

  p:first-child {
    font: var(--important-text-2xl);
  }

  p:last-child {
    color: var(--content-secondary);
    font: var(--important-text-sm);
    text-transform: uppercase;
    max-width: 92px;
  }
}

.weightedAprStat {
  display: none;

  @media (max-width: 1240px) {
    display: flex;
  }
}
// * Summary * //

// * TableCells * //
.headerTitleRow {
  display: flex;
  align-items: center;
  gap: 16px !important;
}

.checkbox {
  height: 18px;
  padding-left: 18px;
}

.delistButton {
  background-color: var(--additional-red-primary-sub) !important;
  color: var(--content-primary) !important;
  width: 96px;

  &:hover {
    border: 1px solid var(--additional-red-primary);
  }

  &:disabled {
    background-color: var(--bg-tertiary) !important;
    color: var(--content-secondary) !important;
  }
}
// * TableCells * //
