.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.offersList {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 8px;
  gap: 8px;
  overflow-y: scroll;
  height: 100vh;
}
