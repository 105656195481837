.tableRoot {
  width: 100%;

  @media (max-width: 1240px) {
    min-width: 490px;
  }

  tr > td,
  tr > th {
    @media (max-width: 1240px) {
      width: 68px;

      &:first-child,
      &:nth-child(4),
      &:last-child {
        width: 118px;
      }
    }

    td {
      padding: 4px;
      font: var(--body-text-sm);
    }
    th {
      background: var(--bg-secondary);

      & > div {
        font: var(--important-text-xs);
      }
    }
  }
}

.tableWrapper {
  height: 280px;
}

.notConnectedTableWrapper {
  @media (max-width: 640px) {
    height: 364px;
  }
}

.statusCellTitle {
  font: var(--body-text-sm);
  white-space: nowrap;
}

.filterTableSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  width: 100%;

  @media (max-width: 640px) {
    padding: 8px 16px;
    flex-direction: column-reverse;
    gap: 16px;
  }
}

.radioButton {
  label {
    padding: 2px 12px;

    @media (max-width: 640px) {
      font: var(--tab-text-sm);
    }
  }

  @media (max-width: 640px) {
    justify-content: center;
  }
}

.collateralCell {
  display: flex;
  align-items: center;
  gap: 8px;
}
.collateralImageWrapper {
  display: flex;
  align-items: center;
  position: relative;

  img,
  svg {
    height: 30px;
    width: 30px;
  }
}
.nftNames {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.nftCollectionName {
  font: var(--body-text-sm);
}

.lentCellTitle,
.aprCellTitle {
  font: var(--body-text-sm);
}

.lentCellTitle span {
  justify-content: flex-end;
}

.badge {
  padding: 0 4px;
  font-size: 10px;
  left: -12px;
  top: -4px;
}

.activityTime {
  font: var(--body-text-sm);
}
