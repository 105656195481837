.tableRoot {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.table {
  min-width: 990px;

  tr > td,
  tr > th {
    &:first-child {
      width: 220px;
    }
  }

  @media (max-width: 640px) {
    min-width: 686px;

    tr > td,
    tr > th {
      width: 96px;

      &:nth-child(4),
      &:last-child {
        width: 114px;
      }

      &:first-child {
        width: 168px;
      }
    }
  }
}

.summary {
  border-top: 1px solid var(--bg-border);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 16px;
    gap: 8px;
  }
}

.mainStat {
  display: flex;
  gap: 8px;
  align-items: center;
  min-width: 160px;

  @media (max-width: 1240px) {
    display: none;
  }

  p:first-child {
    font: var(--important-text-2xl);
  }

  p:last-child {
    color: var(--content-secondary);
    font: var(--important-text-sm);
    text-transform: uppercase;
    max-width: 80px;
  }
}

.statsContainer {
  display: flex;
  justify-content: space-around;
  flex-grow: 1;

  & > div {
    gap: 2px;
    align-items: end;
  }

  @media (max-width: 960px) {
    white-space: nowrap;
    overflow: auto;
    width: 100%;

    & > div {
      align-items: center;
    }
  }

  @media (max-width: 640px) {
    gap: 8px;
  }
}

.summaryButton {
  @media (max-width: 960px) {
    width: 100%;
  }
}

.debtCell {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
}
.debtCellTitle {
  font: var(--body-text-md);

  @media (max-width: 640px) {
    font: var(--body-text-sm);
  }
}
.debtCellSubtitle {
  display: flex;
  align-items: center;
  gap: 2px;
  font: var(--body-text-sm);
}

.statusCellTitle {
  font: var(--body-text-md);

  @media (max-width: 640px) {
    font: var(--body-text-sm);
  }
}
