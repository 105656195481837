// * CollateralLoansCard * //
.card {
  background: var(--bg-primary);
  border-radius: 6px;
  width: 100%;
}

.cardBody {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 12px 24px;
  width: 100%;

  &:hover:not(.expanded) {
    background: var(--action-tertiary);
    border-radius: 6px;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 16px;
  }

  @media (max-width: 640px) {
    &.expanded {
      padding-bottom: 0;
    }
  }
}

.additionalContentWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
}

.expandButton {
  display: flex;

  &.expanded {
    background-color: var(--accent-secondary);

    svg {
      transform: rotate(180deg);
    }
  }
  @media (max-width: 960px) {
    position: absolute;
    top: 8px;
    right: 16px;
  }
}

// * CollateralLoansMainInfo * //
.mainInfoContainer {
  display: flex;
  align-items: center;
  gap: 12px;

  max-width: 290px;
  width: 100%;

  @media (min-width: 961px) and (max-width: 1240px), (max-width: 640px) {
    max-width: 176px;
  }

  @media (max-width: 960px) {
    gap: 8px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.mainInfoContent {
  display: flex;
  flex-direction: column;
}

.collateralPrice {
  display: flex;
  font: var(--body-text-md);

  @media (max-width: 640px) {
    font: var(--body-text-sm);
  }
}

.mainInfoContentRow {
  display: flex;
  align-items: center;
  gap: 2px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  color: var(--content-secondary);
  font: var(--important-text-md);

  @media (max-width: 640px) {
    font: var(--important-text-sm);
  }
}

.collateralImageWrapper {
  display: flex;
  position: relative;
}

.collateralImage {
  border-radius: 100%;
  height: 30px;
  width: 30px;

  @media (max-width: 640px) {
    height: 24px;
    width: 24px;
  }
}

.lendingTokenImage {
  border-radius: 100%;
  position: absolute;
  right: -2px;
  bottom: -2px;

  height: 16px;
  width: 16px;

  @media (max-width: 640px) {
    height: 12px;
    width: 12px;
  }
}
// * CollateralLoansMainInfo * //

// * CollateralLoansAdditionalInfo * //
.additionalInfoStats {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  white-space: nowrap;
  width: 100%;

  @media (max-width: 960px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 8px;
  }
}

.additionalInfoStat {
  align-items: flex-end;
  justify-content: center;
  gap: 2px;
  max-width: 180px;
  width: 100%;

  @media (max-width: 1240px) {
    max-width: 120px;
  }

  @media (max-width: 960px) {
    .expanded &:nth-child(n + 3) {
      visibility: hidden;
      height: 0;
    }

    &:nth-child(-n + 2) {
      grid-row: 1;
    }

    &:nth-child(n + 3) {
      grid-row: 2;
    }

    &:not(:last-child) {
      align-items: flex-start;
    }
  }
}

.additionalInfoStatLabelWrapper {
  @media (min-width: 961px) {
    display: none;
  }
}

.additionalAprStat {
  color: var(--additional-green-primary-deep);
}
// * CollateralLoansAdditionalInfo * //

// * LoansStatus * //
.loansStatus {
  display: flex;
  align-items: center;
}

.loansStatusIcon {
  display: flex;
  align-items: center;

  span {
    font: var(--body-text-md);

    @media (max-width: 640px) {
      font: var(--body-text-sm);
    }
  }
}
