.container {
  display: flex;
  align-items: stretch;
  padding: 24px;
  gap: 24px;
  width: 100%;

  @media (max-width: 960px) {
    flex-direction: column;
  }

  @media (max-width: 640px) {
    padding: 16px;
    gap: 16px;
  }
}

.weeklyRewardsBlock {
  border: var(--border-less-primary);
  border-style: dashed;
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  height: 100%;

  @media (max-width: 640px) {
    padding: 16px;
  }
}
.weeklyRewardsInfoRow {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;

  @media (max-width: 640px) {
    margin-bottom: 16px;
  }
}
.weeklyRewardsInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 640px) {
    gap: 8px;
  }
}
.weeklyRewardsList {
  font: var(--body-text-md);
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  margin-bottom: 8px;
  li {
    display: flex;
    align-items: center;
    column-gap: 8px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: 640px) {
    font: var(--body-text-sm);
    li {
      column-gap: 4px;
      svg {
        height: 18px;
        width: 18px;
      }
    }
  }
}
.bonkRewards {
  background: var(--additional-green-secondary);
  color: var(--additional-green-primary-deep);
  padding: 0 8px;
  border-radius: 100px;

  &Off {
    background: var(--bg-tertiary);
    color: var(--content-secondary);
  }
}
.weeklyRewardsBorrowBtn {
  text-decoration: none;
  button {
    width: 100%;
  }
}

.partnersInfoWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.partnersImages {
  display: flex;

  img {
    border-radius: 100%;
    height: 32px;
    width: 32px;

    @media (max-width: 640px) {
      height: 24px;
      width: 24px;
    }
  }

  img:last-child {
    margin-left: -8px;
  }
}

.rewardsValue {
  font: var(--important-text-3xl);
  white-space: nowrap;

  @media (max-width: 640px) {
    font: var(--important-text-md);
  }
}

.blockTitle {
  font: var(--title-sm);
  text-transform: uppercase;
  white-space: nowrap;

  @media (max-width: 640px) {
    font: var(--title-xs);
  }
}

.availableToClaim {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 0;
  @media (max-width: 640px) {
    padding: 16px 0;
  }
}
.availableToClaimInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.totalClaimedInfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  margin: 16px 0 8px;
}
.totalClaimedLabel {
  color: var(--content-secondary);
  font: var(--important-text-sm);
  text-transform: uppercase;

  @media (max-width: 640px) {
    font: var(--important-text-xs);
  }
}
.totalClaimedValue {
  font: var(--body-text-md);

  @media (max-width: 640px) {
    font: var(--body-text-sm);
  }
}
.claimButton {
  width: 100%;
}

.emptyList {
  margin: 0;
}
