.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  width: 100%;

  @media (max-width: 960px) {
    display: none;
  }
}

.stats {
  display: flex;
  justify-content: flex-end;
  padding-right: 78px;
  gap: 16px;
  width: 100%;
  white-space: nowrap;
}

.mainStat {
  display: flex;
  align-items: center;
  min-width: 152px;
}

.additionalStat {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  max-width: 180px;
  width: 100%;

  @media (max-width: 1240px) {
    max-width: 120px;
  }
}

.statLabel {
  color: var(--content-tertiary);
  font: var(--important-text-sm);
  text-transform: uppercase;
}
