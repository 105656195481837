.pageWrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;

  @media (min-width: 961px) {
    position: relative;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.marketsList {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 8px;
  gap: 8px;
}
