.actionsButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
}
.actionButton {
  width: 90px;
}

.tensorButtonLink {
  &.cardView {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
